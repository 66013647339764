import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import topback from "../images/beetitle.png";
import AR1 from "../pdf/UGC.pdf"
import pdficon from "../images/pdf-icon.jpg";
import { useEffect, useState } from 'react';
function Sparsh() {
  const Urlmain = "https://gcbeetan.co.in/admin/uploads/";
  const [user, setUser] = useState([]);
  const fetchData = () => {
    return fetch("https://gcbeetan.co.in/admin/api/api.php/notifications/sparsh")
      .then((response) => response.json())
      .then((data) => setUser(data));
  }
  useEffect(() => {
    fetchData();
  }, [])
  return (
    <>
      <Header />
      <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
        <div class="pattern-overlay">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 title-heading">
                <h2>SPARSH</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <h2 className="text-center mt-5 mb-5"></h2>
      <section class="container mt-5 mb-5">
        <div class="row">
          <div class="col-md-12">
            <p>
              SPARSH is dedicated to the sensitization, prevention, and redressal of sexual harassment on campus. We are committed to creating a safe and respectful environment for all members of the college community.
            </p>
            <strong>Features:</strong><br />
            <p>- Awareness Programs</p>
            <p>- Reporting Mechanisms</p>
            <p>- Support Services</p>
            <p>- Policy Guidelines</p>
          </div>
        </div>
        <div class="show-list mt-5 pb-5">
          <ul>
            {user && user.length > 0 && user.map((userObj, index) => (
              <li><i class="fa fa-check-circle"></i><a href={Urlmain + userObj.myfile} className="list-inner" target="_blank">{userObj.title}</a></li>
            ))}
          </ul>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-2 d-inline float-none align-self-center"><img class="border rounded pdficon" src={pdficon} style={{ width: '128px;' }} /></div>
            <div class="col-6">
              <p className="pdfpara"><a href={AR1} target="_blank"><strong>“UGC Regulation for Prevention, Prohibition & Rederssal
              of Sexual Harassment of Women Employees and Students</strong></a></p>
            </div>
            <div class="col-2 text-center justify-content-center align-self-center">
              <div class="progress d-flex align-items-lg-center">
              </div>
            </div>
            <div class="col-2 align-self-center"><a href={AR1} target="_blank" class="btn btn-back" style={{ width: '150px;', height: '36px;' }}>Download  <i class="icon ion-ios-arrow-thin-down"></i></a></div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );

}

export default Sparsh;

