import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import topback from "../images/beetitle.png";

import { useEffect, useState } from 'react';
function Evaluation() {
  const Urlmain = "https://gcbeetan.co.in/admin/uploads/";
  const [user, setUser] = useState([]);
  const fetchData = () => {
    return fetch("https://gcbeetan.co.in/admin/api/api.php/notifications/evaluation")
      .then((response) => response.json())
      .then((data) => setUser(data));
  }
  useEffect(() => {
    fetchData();
  }, [])
  return (
    <>
      <Header />
      <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
        <div class="pattern-overlay">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 title-heading">
                <h2>Evaluation/Examination Cell</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <h2 className="text-center mt-5 mb-5"></h2>
      <section class="container mt-5 mb-5">
    <div class="row">
      {/* Dr. Reena Chandel Section */}
      <div class="col-md-6">
        <div class="full mt-5">
          <div className="about-principal">
            <p><strong>Dr Reena Chandel</strong></p>
            <p><strong>Controller of Examination </strong></p>
            <p><strong>House Exam, SDWG Govt College Beetan</strong></p>
          </div>
        </div>
      </div>

      {/* Notifications List Section */}
      <div class="col-md-6">
        <div class="show-list mt-5 pb-5">
          <ul>
            {user && user.length > 0 && user.map((userObj, index) => (
              <li key={index}>
                <i class="fa fa-check-circle"></i>
                <a href={Urlmain + userObj.myfile} className="list-inner" rel="noreferrer" target="_blank">
                  {userObj.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  </section>
      <Footer />
    </>
  );

}

export default Evaluation;