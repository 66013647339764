import React from "react";
import Header from "../pages/Header";
import Footer from "../pages/Footer";
import topback from "../images/beetitle.png";


function OSA(){
    return(
        <>
        <Header />
  <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
  <div class="pattern-overlay">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 title-heading">
        <h2>Alumni/Old Student Association</h2>  
	   </div>
      </div>
    </div>
  </div>
</section>
<div className="container mt-5 pb-5">
    <div className="osa-main pb-5">
        <p>The Old Student Association serves as a vital link between the institution's past and present, fostering a sense of community and continuity among its alumni. This association plays a multifaceted role in preserving the legacy of the alma mater while also enriching the lives of its former students. Through its network, it provides opportunities for alumni to reconnect, reminisce, and support each other professionally and personally. Beyond mere nostalgia, the Old Student Association actively contributes to the development of current students by offering mentorship programs, scholarships, and career guidance. It serves as a repository of institutional memory, preserving traditions, and commemorating milestones. By organizing reunions, events, and community service initiatives, this association ensures that the bonds forged during one's educational journey endure long after graduation. Furthermore, it serves as a platform for alumni to give back to their alma mater, contributing to its continued growth and success. In essence, the Old Student Association is not just a collection of individuals who once attended the institution but a vibrant community united by shared experiences and a commitment to support each other and the institution itself.</p>

        <h4>Objectives of Old Student Association</h4>

        <div className="text-underul">
            <ul>
                <li><strong>Fostering a sense of community:</strong> Bringing former students together to maintain connections, share experiences, and build relationships.</li>
                <li><strong>Supporting the alma mater:</strong> Contributing to the growth and development of the educational institution through various means such as fundraising, volunteering, and advocacy.</li>
                <li><strong>Mentorship and networking:</strong> Providing opportunities for alumni to mentor current students, offer career guidance, and establish professional networks.</li>
                <li><strong>Professional development:</strong> Organizing workshops, seminars, and other events to enhance the skills and knowledge of both alumni and current students.</li>
                <li><strong>Celebrating achievements:</strong> Recognizing the accomplishments of alumni and showcasing their success stories to inspire current students.</li>
                <li><strong>Community service:</strong> Undertaking philanthropic initiatives and volunteering efforts to give back to the local community or society at large.</li>
                <li><strong>Preserving traditions and heritage:</strong> Upholding the values, traditions, and heritage of the alma mater while adapting to changing times.</li>
                <li><strong>Promoting alumni involvement:</strong> Encouraging active participation and engagement of alumni in the association's activities and initiatives.</li>
                <li><strong>Maintaining communication:</strong> Keeping alumni informed about news, events, and developments related to the institution and the association itself.</li>
                <li><strong>Continuing education:</strong> Offering opportunities for lifelong learning, personal growth, and intellectual stimulation through educational programs and resources.</li>
                
            </ul>
        </div>

        <h4>The Vision of the Old Student Association at SDWG College, Beetan</h4>
        <p>The vision of the Old Student Association at SDWG College, Beetan is rooted in a collective perspective towards the institution. The association aims to create a community that enhances social, professional, and spiritual connections among its former students. It envisions the association as a platform that fosters a thriving and supportive community among its members, who uplift and empower each other while promoting accountability. The goal of the association is to encourage students, equip them for professional development, and contribute to positive societal changes. Through various programs, activities, and events, the association inspires its members to actively engage with the organization, leveraging their experiences and contributions to aid the advancement of the institution.</p>
        <h4>HOW TO REGISTER IN GC BEETAN OSA</h4>
        <p>
        Unlock a world of opportunities by becoming a part of GC BEETAN OSA! Registering is a breeze - just click on the OSA Google Form Link provided and fill out the details. Whether you opt for the one-year membership at 100 rupees or go for the lifetime experience with a permanent registration at 200 rupees, you're in for an enriching journey ahead. Don't forget to upload your registration fee receipt slip to seal the deal. Once done, you're all set to enjoy the plethora of benefits that come with being a member of GC BEETAN OSA. Should you have any queries or need assistance, we're here to help every step of the way. Join us today and let's embark on this exciting adventure together!
        </p>
        <strong>Google Link</strong> <a href="https://docs.google.com/forms/d/e/1FAIpQLSfwjagiUPBBj-HhhB6DD-I_aNCZ7EpUspeywxv9pSK-L1bazQ/viewform?usp=sf_link" target="_blank">https://docs.google.com/forms/d/e/1FAIpQLSfwjagiUPBBj-HhhB6DD-I_aNCZ7EpUspeywxv9pSK-L1bazQ/viewform?usp=sf_link</a> 
    </div>
</div>
        <Footer />
        </>
    );
}

export default OSA;