import React from "react";
import Header from "../pages/Header";
import Footer from "../pages/Footer";
import topback from "../images/beetitle.png";
import socityone from "../images/socityone.webp";
import socitytwo from "../images/socitytwo.webp";
import socityhree from "../images/Achievment.jpg";
function students(){
    return(
        <>
           <Header />
        <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
  <div class="pattern-overlay">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 title-heading">
        <h2>Student Achievements 2023-24</h2>  
	   </div>
      </div>
    </div>
  </div>
</section>
<div class="container mt-5 pt-5 pb-5">
           <div class="row">
                        <div class="col-md-6 how-img">
                            <img src={socityone} class="img-fluid" alt=""/>
                        </div>
                        <div class="col-md-6">
                            <h4>SDWG GOVT.COLLEGE BEETAN MOCK INTERVIEW SESSION:</h4>
                                        <h4 class="subheading">REPORT DATED:10/11/2023</h4>
                        <p class="text-muted">A mock interview session was organized on November 10, 2023, in the conference hall. Dr. Anjori Sharma, principal of the college, gave a welcome address and apprised the participants about the objective and purpose of this mock interview session. She asserted that the mock interview sessions will benefit students' future career-related skills and job opportunities. The mock interview session panel was headed by Prof.Gurdev S</p>
                        </div>
                    </div>
                    <div class="row mt-5 mb-5 pt-5">
                        <div class="col-md-6">
                            <h4></h4>
                                        <h4 class="subheading"></h4>
                        <p class="text-muted">our college drama team got second position in the distt. level drama competition organized by CMO Una to spread awareness about HIV aids.</p>
                        </div>
                        <div class="col-md-6 how-img">
                            <img src={socitytwo} class="img-fluid" alt=""/>
                        </div>
                        <div class="col-md-6 how-img">
                            <img src={socityhree} class="img-fluid" alt=""/>
                        </div>
                    </div>
                    </div>
                    <Footer />
      </>
        );

}

export default students;