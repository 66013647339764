import React from "react";
import Header from "../pages/Header";
import Footer from "../pages/Footer";
import Contactf from "../images/img10.png";
import topback from "../images/beetitle.png";
function Contact() {
   return (
      <>
         <Header />
         <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
            <div class="pattern-overlay">
               <div class="container">
                  <div class="row">
                     <div class="col-lg-12 title-heading">
                        <h2>Contact Us</h2>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <div class="section contact_section" style={{ background: '' }}>
            <div class="container">
               <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-12">
                     <div class="full float-right_img mt-5 pt-5">
                        <img src={Contactf} alt="#" />
                     </div>
                  </div>
                  <div class="layout_padding col-lg-6 col-md-6 col-sm-12">
                     <div class="contact_form">
                        <form action="contact.html">
                           <fieldset>
                              <div class="full field">
                                 <input type="text" placeholder="Your Name" name="your name" />
                              </div>
                              <div class="full field">
                                 <input type="email" placeholder="Email Address" name="Email" />
                              </div>
                              <div class="full field">
                                 <input type="phn" placeholder="Phone Number" name="Phone number" />
                              </div>
                              <div class="full field">
                                 <textarea placeholder="Message"></textarea>
                              </div>
                              <div class="full field">
                                 <div class="center"><button>Send</button></div>
                              </div>
                           </fieldset>
                        </form>
                     </div>
                  </div>
               </div>
               <div>
      <h2>Our Location</h2>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3408.0110802481336!2d76.24005149999999!3d31.3310671!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391ac66dcedc62e3%3A0x6ba6a42852ce27b!2sBeeton%20Singan%20Rd%2C%20Beeton%2C%20Himachal%20Pradesh%20174601!5e0!3m2!1sen!2sin!4v1741179662405!5m2!1sen!2sin"
        width="100%"
        height="400"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
            </div>
         </div>
         <Footer />
      </>
   );
}

export default Contact;