import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Logo from "../images/logo1.png";
import { useEffect, useState } from 'react';
import searchIcon from "../images/search_icon.png"

function Header() {

    const [searchResults, setSearchResults] = useState([]); // For storing search results
    const [query, setQuery] = useState(""); // For storing the input value

    // Define your navigation links
    const navLinks = [
        { name: "Home", path: "/" },
        { name: "Overview", path: "/about" },
        { name: "Vision & Mission", path: "/vision" },
        { name: "Institutional Development Plan", path: "/inst_development" },
        { name: "Annual Reports", path: "/annualreport" },
        { name: "Principal", path: "/principal" },
        { name: "Faculty", path: "/faculty" },
        { name: "Administration Staff", path: "/administationstaff" },
        { name: "Chemistry", path: "/chemistry" },
        { name: "Economics", path: "/economics" },
        { name: "Commerce", path: "/commerce" },
        { name: "Hindi", path: "/hindi" },
        { name: "Math", path: "/math" },
        { name: "Physics", path: "/physics" },
        { name: "Political Science", path: "/political_science" },
        { name: "English", path: "/english" },
        { name: "History", path: "/history" },
        { name: "Academic Calendar", path: "/acadimicsCalender" },
        { name: "Evaluation/Examination/Cell", path: "/evaluation" },
        { name: "Online Admission", path: "/onlineadmission" },
        { name: "Committees", path: "/Committees" },
        { name: "E-Learning Resources", path: "/e_learning" },
        { name: "Sports", path: "/sports" },
        { name: "SGRC", path: "/sgrc" },
        { name: "Health", path: "/health" },
        { name: "CSCA", path: "/csca" },
        { name: "College Magazine", path: "/college_magazine" },
        { name: "Scholarship", path: "/scholarship" },
        { name: "Eco Club", path: "/eco_club" },
        { name: "Energy Club", path: "/energy_Club" },
        { name: "Youth Red Cross", path: "/youth_red_cross" },
        { name: "Electoral Literacy Clubs", path: "/electoral_literacy_clubs" },
        { name: "Red Ribbon Club", path: "/red_ribbon_club" },
        { name: "Road Safety Club", path: "/road_safety_club" },
        { name: "NSS", path: "/nss" },
        { name: "NCC", path: "/ncc" },
        { name: "Rangers & Rovers", path: "/ranger_and_rover" },
        { name: "Career Guidance & Placement Cell", path: "/career_guidance_cell" },
        { name: "Academic Bank of Credits", path: "/acadmicbankofcredits" },
        { name: "Naac", path: "/naac" },
        { name: "AQAR", path: "/AQAR" },
        { name: "AISHE", path: "/aishe" },
        { name: "SSR/SAR", path: "/ssr" },
        { name: "UGC/NEP", path: "/ugc" },
        { name: "Collaborations", path: "/collaboration" },
        { name: "SPARSH", path: "/sparsh" },
        { name: "Gallery", path: "/gallery" },
        { name: "PTA", path: "/PTA" },
        { name: "OSA", path: "/osa" },
        { name: "Infrastructure", path: "/infrastructure" },
        { name: "Library", path: "/library" },
        { name: "Student Achievements", path: "/students-achievements" },
        { name: "Contact Us", path: "/contact" },
        { name: "RTI", path: "/rti" },
        { name: "Facilities", path: "/facilities" },
    ];
    
    

    // Function to fetch search results from the API
    const fetchSearchResults = (searchQuery) => {
        fetch(`https://gcbeetan.co.in/admin/api/search.php?query=${encodeURIComponent(searchQuery)}`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Error fetching search results");
                }
                return response.json();
            })
            .then((data) => {
                // Combine API results with filtered local results
                setSearchResults((prevResults) => [
                    ...prevResults,
                    ...data.database_results.map((item) => ({
                        name: item.title,
                        path: item.file_path,
                    })),
                ]);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    // Handle form submission
    const handleSearch = (event) => {
        event.preventDefault(); // Prevent page reload

        // Filter local navigation links
        const filteredLinks = navLinks.filter((link) =>
            link.name.toLowerCase().includes(query.toLowerCase())
        );

        // Set local results first, then fetch API results
        setSearchResults(filteredLinks);
        fetchSearchResults(query); // Fetch results for the entered query
    };

    return (
        <>
            {/* <!-- LOADER --> */}

            {/* <!-- end loader -->
    <!-- END LOADER -->

    <!-- Start header --> */}
            <header className="top-header">
                <div className="header-content">
                    <p className="logo-text">Sant Dhanguwala Gujjar Govt College Beetan</p>

                    {/* Search bar added here */}
                    <form onChange={handleSearch} class="search-form" id="searchForm">
                        <input
                            type="text"
                            className="search-input"
                            placeholder="Search..."
                            aria-label="Search"
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                        />
                        <button type="submit" class="search-btn">
                            <img src={searchIcon} alt="Search" className="search-icon" />
                        </button>
                    </form>


                    {/* Search Results */}
                {searchResults.length > 0 && (
                    <div className="search-results">
                        {searchResults.map((result, index) => (
                            <div key={index} className="search-result-item">
                                <Link to={result.path} className="search-result-link">
                                    {result.name}
                                </Link>
                            </div>
                        ))}
                    </div>
                    )}
                    {/* <nav className="navbar-nav">
                    <ul>
                        {navLinks.map((link, index) => (
                            <li key={index}>
                                <Link to={link.path} className="nav-link">
                                    {link.name}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </nav> */}
                </div>
                <nav className="navbar header-nav navbar-expand-lg">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="/"><img src={Logo} alt="" />
                        </a>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-wd" aria-controls="navbar-wd" aria-expanded="false" aria-label="Toggle navigation">
                            <span></span>
                            <span></span>
                            <span></span>
                        </button>
                        <div className="collapse navbar-collapse justify-content-end" id="navbar-wd">
                            <ul className="navbar-nav">
                                <li><a href="/" className="nav-link active">Home</a></li>
                                <li id="drop-menu"><Link to="#" className="nav-link active">About us </Link>
                                    <ul className="drop-main" id="open-about">
                                        <li><Link to="/about" className="nav-link">Overview</Link></li>
                                        <li><Link to="/vision" className="nav-link">Vision & Mission</Link></li>
                                        <li><Link to="/inst_development" className="nav-link">Institutional Development plan</Link></li>
                                        <li><Link to="/annualreport" className="nav-link">Annual Reports</Link></li>
                                    </ul>
                                </li>
                                <li id="drop-menu"><Link to="#" className="nav-link open-staff">Administration <span><i class=""></i></span></Link>
                                    <ul className="drop-main" id="open-staff">
                                        <li><Link to="/principal" className="nav-link">Principal</Link></li>
                                        <li><Link to="/faculty" className="nav-link">Facutly</Link></li>
                                        <li><Link to="/administationstaff" className="nav-link">Administation Staff</Link></li>
                                    </ul>
                                </li>
                                <li id="drop-menu"><Link to="#" className="nav-link open-academics">Academics <span><i class=""></i></span></Link>
                                    <ul className="drop-main" id="closed-academics">
                                        <li className="second-menu"><Link to="#" className="nav-link">Head of Departments <i class='' style={{ fontsize: '24px' }}></i></Link>
                                            <ul className="second-inner">
                                                <li><Link to="/chemistry" className="nav-link">Chemistry</Link></li>
                                                <li><Link to="/economics" className="nav-link">Economics</Link></li>
                                                <li><Link to="/commerce" className="nav-link">Commerce</Link></li>
                                                <li><Link to="/hindi" className="nav-link">Hindi</Link></li>
                                                <li><Link to="/math" className="nav-link">Math</Link></li>
                                                <li><Link to="/physics" className="nav-link">Physics</Link></li>
                                                <li><Link to="/political_science" className="nav-link">Political Science</Link></li>
                                                <li><Link to="/english" className="nav-link">English</Link></li>
                                                <li><Link to="/history" className="nav-link">History</Link></li>
                                            </ul>
                                        </li>
                                        <li><Link to="/acadimicsCalender" className="nav-link">Academic Calendar</Link></li>
                                        <li><Link to="/evaluation" className="nav-link">Evaluation / Examination / CELL </Link></li>
                                    </ul>
                                </li>
                                <li id="drop-menu"><Link to="/onlineadmission" className="nav-link active">Online Admission </Link></li>
                                <li id="drop-menu"><Link to="#" className="nav-link open-student">Student Zone<span><i class=""></i></span></Link>
                                    <ul className="drop-main" id="open-student">
                                        <li><Link to="/Committees" className="nav-link">COMMITTEES</Link></li>
                                        <li><Link to="/e_learning" className="nav-link">e- learning resources</Link></li>
                                        <li><Link to="/sports" className="nav-link">Sports</Link></li>
                                        <li><Link to="/sgrc" className="nav-link">SGRC</Link></li>
                                        <li><Link to="/health" className="nav-link">Health</Link></li>
                                        <li><Link to="/csca" className="nav-link">CSCA</Link></li>
                                        <li><Link to="/college_magazine" className="nav-link">College Magazine</Link></li>
                                        <li><Link to="/scholarship" className="nav-link">Scholarship</Link></li>
                                        <li><Link to="/facilities" className="nav-link">Facilities for differently abled</Link></li>
                                        <li className="second-menu"><Link to="#" className="nav-link">clubs and societies <i class='' style={{ fontsize: '24px' }}></i></Link>
                                            <ul className="second-inner">
                                                <li><Link to="/eco_club" className="nav-link">Eco club</Link></li>
                                                <li><Link to="/energy_Club" className="nav-link">Energy Club</Link></li>
                                                <li><Link to="/youth_red_cross" className="nav-link">Youth Red Cross</Link></li>
                                                <li><Link to="/electoral_literacy_clubs" className="nav-link">Electoral Literacy Clubs</Link></li>
                                                <li><Link to="/red_ribbon_club" className="nav-link">Red Ribbon club</Link></li>
                                                <li><Link to="/road_safety_club" className="nav-link">Road Safety Club</Link></li>
                                            </ul>
                                        </li>
                                        <li><Link to="/nss" className="nav-link">NSS</Link></li>
                                        <li><Link to="/ncc" className="nav-link">NCC</Link></li>
                                        <li><Link to="/ranger_and_rover" className="nav-link">Rangers & Rovers</Link></li>
                                        <li><Link to="/career_guidance_cell" className="nav-link">Career guidance & placement cell</Link></li>

                                        <li><Link to="/acadmicbankofcredits" className="nav-link">Academic Bank of credits</Link></li>
                                    </ul>
                                </li>

                                {/* <li><Link to="/csca" className="nav-link">CSCA</Link></li> */}
                                <li id="drop-menu"><Link to="/iqac" className="nav-link">IQAC <span><i class=""></i></span></Link>
                                    <ul className="drop-main">
                                        <li><Link to="/naac" className="nav-link">Naac</Link></li>
                                        <li><Link to="/AQAR" className="nav-link">AQAR</Link></li>
                                        <li><Link to="/aishe" className="nav-link">AISHE</Link></li>
                                        <li><Link to="/ssr" className="nav-link">SSR/SAR</Link></li>
                                        {/* <li className="second-menu"><Link to="#" className="nav-link">Institutional Best Practices <i class='' style={{fontsize:'24px'}}></i></Link>
                            <ul className="second-inner">
                                <li><Link to={Bestprectice201920} target="_blank" className="nav-link">2019-20</Link></li>
                            </ul>
                            </li> */}
                                        <li><Link to="/ugc" className="nav-link">UGC/NEP</Link></li>
                                    </ul>
                                </li>
                                <li id="drop-menu"><Link className="nav-link">Miscellaneous <span><i class=""></i></span></Link>
                                    <ul className="drop-main">
                                        <li><Link to="/collaboration" className="nav-link">Research/Collaboration</Link></li>
                                        <li><Link to="/sparsh" className="nav-link">SPARSH</Link></li>
                                        {/* <li><Link to="/career_guidance_cell" className="nav-link">Career Guidance Cell</Link></li> */}
                                        {/* <li><Link to="/csca" className="nav-link">CSCA</Link></li> */}
                                        <li><Link to="/gallery" className="nav-link">Gallery</Link></li>
                                        <li><Link to="/PTA" className="nav-link">PTA</Link></li>
                                        <li><Link to="/osa" className="nav-link"> Alumni/OSA</Link></li>
                                        <li><Link to="/rti" className="nav-link">RTI</Link></li>
                                        <li className="second-menu"><Link to="#" className="nav-link">Downloads <i class='' style={{ fontsize: '24px' }}></i></Link>
                                            <ul className="second-inner">
                                                <li><Link to="#" className="nav-link">For Students</Link></li>
                                                <li className="second-menu-inner"><Link to="#" className="nav-link">For Staff <i class='' style={{ fontsize: '24px' }}></i></Link>
                                                    <ul className="second-inner-third">
                                                        <li><Link to="/noc" className="nav-link">Noc's</Link></li>
                                                        <li><Link to="#" className="nav-link">Forms / Proformas</Link></li>
                                                        <li><Link to="#" className="nav-link">Notification / Letter</Link></li>
                                                    </ul>
                                                </li>

                                            </ul>
                                        </li>
                                        <li><Link to="/infrastructure" className="nav-link">INFRASTRUCTURE</Link></li>
                                        <li><Link to="/library" className="nav-link">LIBRARY</Link></li>
                                        <li><Link to="/students-achievements" className="nav-link">Achievements</Link></li>
                                    </ul>
                                </li>

                                <li id="drop-menu"><Link to="/contact" className="nav-link">Contact Us</Link>
                                </li>


                                <li><a className="nav-link login-btn" href="https://admission.gcbeetan.co.in/">Online Addmission</a></li>
                            </ul>
                        </div>
                        {/* <div className="search-box">
                    <input type="text" className="search-txt" placeholder="Search" />
                    <a className="search-btn">
                        <img src="images/search_icon.png" alt="#" />
                    </a>
                </div> */}

                    </div>

                </nav>

            </header>
            {/* <!-- End header --> */}
        </>
    );
}
export default Header;