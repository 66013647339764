import React from "react";
import Header from "../pages/Header";
import Footer from "../pages/Footer";
import topback from "../images/beetitle.png";
import { useEffect, useState } from 'react';
import NCC from "../images/NCC.jpg";
function Ncc() {
  const Urlmain = "https://gcbeetan.co.in/admin/uploads/";
  const [user, setUser] = useState([]);
  const fetchData = () => {
    return fetch("https://gcbeetan.co.in/admin/api/api.php/notifications/ncc")
      .then((response) => response.json())
      .then((data) => setUser(data));
  }
  useEffect(() => {
    fetchData();
  }, [])
  return (
    <>
      <Header />
      <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
        <div class="pattern-overlay">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 title-heading">
                <h2>NCC</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <div class="full mt-5">
              <img src={NCC} alt="#" />
            </div>
          </div>
          <div class="col-md-8 layout_padding_2 mt-5">
            <p>
              <strong>NCC (National Cadet Corps):</strong><br />
              The National Cadet Corps (NCC) at our college offers students an excellent opportunity to engage in a wide range of military training and leadership development activities. As part of the NCC, students participate in regular drill practice, adventure camps, and community service initiatives that help instill discipline and a sense of patriotism. Cadets also gain valuable life skills, such as teamwork, time management, and problem-solving, that can benefit them in both their personal and professional lives.

            </p>
          </div>
        </div>
        <div class="show-list mt-5 pb-5">
          <ul>
            {user && user.length > 0 && user.map((userObj, index) => (
              <li><i class="fa fa-check-circle"></i><a href={Urlmain + userObj.myfile} className="list-inner" target="_blank">{userObj.title}</a></li>
            ))}
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Ncc;