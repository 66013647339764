import React from "react";
import Header from "../pages/Header";
import Footer from "../pages/Footer";

import topback from "../images/beetitle.png";

function Rti(){
    return(
        <>
        <Header />
        <div className="section">
        {/* <!--Video Section--> */}
<section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
  <div class="pattern-overlay">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 title-heading">
        <h2>RTI</h2>  
	   </div>
      </div>
    </div>
  </div>
</section>
 </div>
 <div class="section margin-top_50">
          <div class="container">
            <div class="row">

              <div className="col-md-12 layout_padding_2" style={{width:'100%'}}>
                <div className="full">
                  <div className="heading_main text_align_left">
                    <h2><span>Right to Information (RTI)</span> - SDWG Govt College Beetan</h2>
                  </div>
                  <div className="full">
                    <p>The Right to Information Act, 2005, empowers citizens to seek information from public authorities to promote transparency and accountability in governance. SDWG Govt College Beetan, being a government institution, abides by the RTI Act and ensures the availability of necessary information to the public.</p>
                    <h3>Objectives of RTI</h3>
                    <ul>
                      <li>Promote transparency and accountability in the functioning of the college.</li>
                      <li>Provide access to information regarding academic, administrative, and financial matters.</li>
                      <li>Ensure compliance with the RTI Act, 2005.</li>
                    </ul>
                    <h3>Information Available Under RTI</h3>
                    <ul>
                      <li>1. Courses offered, faculty details, and academic programs.</li>
                      <li>2. Admission procedures, fee structure, and scholarships.</li>
                      <li>3. College administration, policies, and guidelines.</li>
                      <li>4. Annual reports, budget allocations, and expenditure details.</li>
                      <li>5. Any other information permissible under the RTI Act, 2005.</li>
                    </ul>
                    <h3>How to File an RTI Application</h3>
                    <ol>
                      <li>1. Prepare the Application: Write a formal request mentioning the required information.</li>
                      <li>2. Address the Application: Send the application to the designated Public Information Officer (PIO) of the college.</li>
                      <li>3. Mode of Submission: Applications can be submitted in person, via post, or electronically (if applicable).</li>
                      <li>4. Fee Payment: As per government norms, an application fee (typically ₹10) is payable via cash, demand draft, or other prescribed modes.</li>
                      <li>5. Response Time: The PIO shall respond within 30 days from the date of receipt of the application.</li>
                    </ol>
                    <h3>Public Information Officer (PIO)</h3>
                    <p><strong>Name:</strong> Mr. Rajeev Kumar</p>
                    <p><strong>Designation:</strong> Public Information Officer, SDWG Govt College Beetan</p>
                    <p><strong>Address:</strong> SDWG, Govt College Beetan, Teh Haroli, Distt: Una, HP.</p>
                    <p><strong>Email:</strong> principalgcbeetan@gmail.com</p>
                    <h3>Appellate Authority</h3>
                    <p><strong>Name:</strong> Dr. Sunita Goyal</p>
                    <p><strong>Designation:</strong> Principal, First Appellate Authority, SDWG Govt College Beetan</p>
                    <p><strong>Address:</strong> SDWG, Govt College Beetan, Teh Haroli, Distt: Una, HP</p>
                    <p><strong>Email:</strong> principalgcbeetan@gmail.com</p>
                    <h3>Exemptions Under RTI Act</h3>
                    <ul>
                      <li>1. Information affecting national security and sovereignty.</li>
                      <li>2. Personal information unrelated to public interest.</li>
                      <li>3. Confidential information protected under the law.</li>
                    </ul>
                    <p>For further details, applicants can refer to the official RTI Act, 2005 Government of India RTI Portal.</p>
                    <p><strong>Disclaimer:</strong> The information provided on this page is subject to updates as per government norms and institutional policies.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

<Footer />
        </>
    );
}

export default Rti;