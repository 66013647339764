import React from "react";
import Header from "../pages/Header";
import Footer from "../pages/Footer";
import topback from "../images/beetitle.png";
import pdficon from "../images/pdf-icon.jpg";
import instdevelopment1 from "../pdf/Institutional_Development_Plan_final.2023-24.pdf"
import instdevelopment2 from "../pdf/IDP.pdf"
function Instdevelopment() {
    return (
      <>
        <Header />
        <div className="section">
          {/* <!--Video Section--> */}
          <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
            <div class="pattern-overlay">
              <div class="container">
                <div class="row">
                  <div class="col-lg-12 title-heading">
                    <h2>Institutional Development plan</h2>
                  </div>
                </div>
              </div>
            </div>
          </section>
              <div className="section margin-top_50 margin-bottom_50">
                      <div class="container">
                          <div class="row">
                              <div class="col-2 d-inline float-none align-self-center"><img class="border rounded pdficon" alt=" " src={pdficon} style={{width: '128px;'}} /></div>
                              <div class="col-6">
                                  <p className="pdfpara"><a href={instdevelopment1} target="_blank"><strong>Institutional_Development_Plan_final.2023-24</strong></a></p>
                              </div>
                              <div class="col-2 text-center justify-content-center align-self-center">
                                  <div class="progress d-flex align-items-lg-center">
                                   </div>
                              </div>
                              <div class="col-2 align-self-center"><a href={instdevelopment1} target="_blank" class="btn btn-back" style={{width: '150px;', height: '36px;'}}>Download  <i class="icon ion-ios-arrow-thin-down"></i></a></div>
                          </div>
                      </div>
                  </div>
              <div className="section margin-top_50 margin-bottom_50">
                      <div class="container">
                          <div class="row">
                              <div class="col-2 d-inline float-none align-self-center"><img class="border rounded pdficon" alt=" " src={pdficon} style={{width: '128px;'}} /></div>
                              <div class="col-6">
                                  <p className="pdfpara"><a href={instdevelopment2} target="_blank"><strong>Institutional_Development_Plan_final.2024-25</strong></a></p>
                              </div>
                              <div class="col-2 text-center justify-content-center align-self-center">
                                  <div class="progress d-flex align-items-lg-center">
                                   </div>
                              </div>
                              <div class="col-2 align-self-center"><a href={instdevelopment2} target="_blank" class="btn btn-back" style={{width: '150px;', height: '36px;'}}>Download  <i class="icon ion-ios-arrow-thin-down"></i></a></div>
                          </div>
                      </div>
                  </div>
        </div>

  
        <Footer />
      </>
    );
  }
  
  export default Instdevelopment;