import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import topback from "../images/beetitle.png";
import IMG1 from "../images/differentlyabled.jpg"
import IMG2 from "../images/differentlyabled1.jpg"
import IMG3 from "../images/differentlyabled2.jpg"

import pdficon from "../images/pdf-icon.jpg";
function Facilities() {
    return (
        <>
            <Header />
            <div className="section">
                <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
                    <div class="pattern-overlay">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-12 title-heading">
                                    <h2>Facilities for differently abled</h2>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="section margin-top_50">
                    <div class="container">
                        <div class="row">

                            <div className="col-md-8 layout_padding_2" style={{ width: '100%' }}>
                                <div className="full">
                                    <div className="full">
                                        <p>Our college is committed to creating an inclusive environment for all students. To ensure accessibility, we have equipped the campus with well-designed ramp that provide easy access to classrooms and other facilities for differently-abled students. This ramp is constructed with appropriate slopes, anti-slip surfaces, and safety railings to prioritize comfort and independence.</p>
                                        <div className="facility-images text-center">
                                        <img src={IMG1} alt="Ramp Facility 1" className="img-fluid mx-2" style={{ maxWidth: "300px" }} />
                                        <img src={IMG2} alt="Ramp Facility 2" className="img-fluid mx-2" style={{ maxWidth: "300px" }} />
                                        <img src={IMG3} alt="Ramp Facility 3" className="img-fluid mx-2" style={{ maxWidth: "300px" }} />
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>


            <Footer />
        </>
    );
}

export default Facilities;