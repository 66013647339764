import React from "react";
import Header from "../pages/Header";
import Footer from "../pages/Footer";
import topback from "../images/beetitle.png";

import P1 from "../images/allmas.jpg";
import P2 from "../images/second.jpg";
import P3 from "../images/thrid.webp";
import P4 from "../images/beetanclg.jpg";
import P5 from "../images/five.webp";
import P6 from "../images/six.webp";
import P7 from "../images/seven.webp";
import P8 from "../images/eight.webp";
import P9 from "../images/beetanclg.jpg";
import P10 from "../images/ten.webp";
import { useEffect, useState } from 'react';

function Photogallery() {
	const [images, setImages] = useState([]);

	const fetchgallery = () => {
		fetch("https://gcbeetan.co.in/admin/api/photo_galleryapi.php")
			.then((response) => response.json())
		
			.then((data) => {
				console.log("Fetched images:", data);
				const sortedImages = data.sort((a, b) => b.timestamp - a.timestamp);
				setImages(sortedImages);
				
			})

			.catch((error) => console.error("Error fetching images:", error));
	};

	useEffect(() => {
		fetchgallery();
	}, []);

	return (
		<>
			<Header />
			<section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>

				<div class="pattern-overlay">
					<div class="container">
						<div class="row">
							<div class="col-lg-12 title-heading">
								<h2>Gallery</h2>
							</div>
						</div>
					</div>
				</div>
			</section>
			<div className="gallery__page silver_bg">
				<div className="gallery__warp">
					<div className="container mt-5 mb-5 pb-5">
						<div className="row pt-5">
							{images.length > 0 ? (
								images.map((image, index) => (
									<div key={index} className="col-md-4 col-sm-6 mb-4">
										<div className="gallery__item">
											<a href={image.url} className="fresco" data-fresco-group="gallery">
												<img
													className="img-fluid gallery__image"
													src={image.url}
													alt={image.title ? image.title : `Gallery item ${index + 1}`}
													loading="lazy"
												/>
											</a>
										</div>
									</div>
								))
							) : (
								<div className="col-12 text-center">
									<p>No images found</p>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
}

export default Photogallery;