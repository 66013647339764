import React from "react";
import Header from "../pages/Header";
import Footer from "../pages/Footer";
import topback from "../images/beetitle.png";
import P8 from "../images/csca.jpg";
import { useEffect, useState } from 'react';
function Csca(){
  const Urlmain = "https://gcbeetan.co.in/admin/uploads/";
  const [user, setUser] = useState([]);
  const fetchData = () => {
    return fetch("https://gcbeetan.co.in/admin/api/api.php/notifications/csca")
          .then((response) => response.json())
          .then((data) => setUser(data));
  }
    useEffect(() => {
    fetchData();
  },[])
    return(
        <>
        <Header />
        <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
  <div class="pattern-overlay">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 title-heading">
        <h2>CSCA</h2>  
	   </div>
      </div>
    </div>
  </div>
</section>
{/* <!-- section --> */}
<div class="section margin-top_50">
        <div class="container mb-5 pb-5">
            <div class="row">
            <div class="col-md-4">
                    <div class="full mt-5">
                        <img src={P8} alt="#" />
                    </div>
                </div>
                <div class="col-md-8 layout_padding_2">
                    <div class="full">
						<div class="full">
						  <p> CSCA for the session 2024-2025 on the basis of academic merit and nominations in r/o SDWG GOVT.COLLEGE BEETAN. The detail of the CSCA office bearers is as under:
List of office Bearers CSCA SDWG GOVT. College Beetan for the session 2024-2025 
</p>
						</div>
            <table class="table">
    <thead>
      <tr>
        <th>Post</th>
        <th>Name</th>
        <th>Roll No.</th>
        <th>Class</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>President</td>
        <td>Riya</td>
        <td>1222570032</td>
        <td>B.A.3rd Year</td>
      </tr>
      <tr>
        <td>Vice President</td>
        <td>Chirag</td>
        <td>2232570002</td>
        <td>B.Sc 2nd Year</td>
      </tr>
      <tr>
        <td>Secretary</td>
        <td>Mansi</td>
        <td>1232570018</td>
        <td>B.A.2nd Year</td>
      </tr>
      <tr>
        <td>Joint Secretary </td>
        <td>Kanika</td>
        <td>24BCM013</td>
        <td>B.COM 1st Year </td>
      </tr>
    </tbody>
  </table>
  <h6>Committee Members:</h6>

<p>1. Prof. Gurdev Singh</p> 
<p>2. Dr Uma Rani</p>
<p>3. Dr Gaurav Sharma</p>
<p>4. Dr Daleep Bali</p>
<p>5. Prof Richa Sharma	</p>

<div class="full">
                        
                        <div className="about-principal">
                        <p><strong>Dr. Sunita Goyal</strong></p>
                        <p><strong>Principal SDWG Govt. College Beetan</strong></p>
                        <p><strong>Distt. Una H.P.</strong></p>
                        </div>
                    </div>
						
                    </div>
                </div>
            </div>
            <div class="show-list mt-5 pb-5">
                <ul>
                {user && user.length > 0 && user.map((userObj, index) => (
                    <li><i class="fa fa-check-circle"></i><a href={Urlmain+userObj.myfile} className="list-inner" target="_blank">{userObj.title}</a></li>
                    ))}
                </ul>
</div>
        </div>
    </div>
	{/* <!-- end section --> */}
  <Footer />
        </>
    );
}

export default Csca;