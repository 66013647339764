import React from "react";
import Header from "../pages/Header";
import Footer from "../pages/Footer";

import topback from "../images/beetitle.png";

function Committees(){
    return(
        <>
        <Header />
        <div className="section">
        {/* <!--Video Section--> */}
<section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
  <div class="pattern-overlay">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 title-heading">
        <h2>Committees</h2>  
	   </div>
      </div>
    </div>
  </div>
</section>
 </div>
 <div class="container mt-5">
    <div class="row">
      
        <div class="col-md-6">
            <div class="committee-card">
                <div class="card-header">
                    <h3 class="m-0">Internal complaint committee/Sexual Harassment Redressal Committee</h3>
                </div>
                <div class="card-body">
                    <ul>
                        <li>Dr Uma Rani (Associate professor)</li>
                        <li>Dr Reena chandel (Assistant Professor)</li>
                        <li>Prof Gurjeet Kaur (Assistant Professor)</li>
                        <li>Ms Manpreet Kaur (Student BA IIIrd year)</li>
                        <li>Ms Riya (President CSCA)</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="committee-card">
                <div class="card-header">
                    <h3 class="m-0">College Advisory & CSCA Committee</h3>
                </div>
                <div class="card-body">
                    <ul>
                        <li>Mr. Gurdev Singh</li>
                        <li>Dr. Uma Rani</li>
                        <li>Dr. Daleep Bali</li>
                        <li>Dr. Gaurav Sharma</li>
                        <li>Ms. Sunita Rani (Librarian)</li>
                        <li>Shri Ashok Kumar (Supdt. Grade-II)</li>
                    </ul>
                </div>
            </div>
        </div>

       
        <div class="col-md-6">
            <div class="committee-card">
                <div class="card-header">
                    <h3 class="m-0">Anti-Ragging and Discipline Committee</h3>
                </div>
                <div class="card-body">
                    <ul>
                        <li>Mr. Gurdev Singh</li>
                        <li>Dr. Uma Rani</li>
                        <li>Dr. Daleep Bali</li>
                        <li>Dr. Gaurav Sharma</li>
                        <li>Dr. Reena Chandel</li>
                    </ul>
                </div>
            </div>
        </div>

  
        <div class="col-md-6">
            <div class="committee-card">
                <div class="card-header">
                    <h3 class="m-0">Prospectus & College Online Admission Committee</h3>
                </div>
                <div class="card-body">
                    <ul>
                        <li>Dr. Daleep Bali</li>
                        <li>Dr. Gaurav Sharma</li>
                        <li>Dr. Reena Chandel</li>
                        <li>Ms. Richa Sharma</li>
                        <li>Shri Ashok Kumar (Storekeeper)</li>
                    </ul>
                </div>
            </div>
        </div>

       
        <div class="col-md-6">
            <div class="committee-card">
                <div class="card-header">
                    <h3 class="m-0">Scholarship / Fee Concession Committee</h3>
                </div>
                <div class="card-body">
                    <ul>
                        <li>Dr. Uma Rani</li>
                        <li>Ms. Richa Sharma</li>
                        <li>Ms. Jyoti Sharma</li>
                        <li>Shri Ashok Kumar (Storekeeper)</li>
                    </ul>
                </div>
            </div>
        </div>

     
        <div class="col-md-6">
            <div class="committee-card">
                <div class="card-header">
                    <h3 class="m-0">RUSA 2.0/PM USHA and UGC Cell</h3>
                </div>
                <div class="card-body">
                    <ul>
                        <li>Dr. Gaurav Sharma</li>
                        <li>Ms. Gurjeet Kaur</li>
                        <li>Mr. Ramesh Kumar</li>
                        <li>Shri Ashok Kumar (Storekeeper)</li>
                    </ul>
                </div>
            </div>
        </div>

     
        <div class="col-md-6">
            <div class="committee-card">
                <div class="card-header">
                    <h3 class="m-0">Internal Quality Assurance Cell (IQAC/NAAC) Committee</h3>
                </div>
                <div class="card-body">
                    <ul>
                        <li>Principal: Chairperson</li>
                        <li>Mr. Gurdev Singh (Convener)</li>
                        <li>Dr. Daleep Bali</li>
                        <li>Dr. Gaurav Sharma</li>
                        <li>Dr. Reena Chandel</li>
                        <li>Ms. Sunita Rani (Librarian)</li>
                        <li>Dr. Abhilasha Sharda (Retired Principal)</li>
                        <li>Dr. Y.P. Saini (Retired Professor)</li>
                        <li>Shri Ashok Kumar (Supdt. Grade-II)</li>
                    </ul>
                </div>
            </div>
        </div>

        
        <div class="col-md-6">
            <div class="committee-card">
                <div class="card-header">
                    <h3 class="m-0">Bus Pass Committee</h3>
                </div>
                <div class="card-body">
                    <ul>
                        <li>Dr. Uma Rani</li>
                        <li>Mr. Butta Ram</li>
                        <li>Shri Ashok Kumar (Storekeeper)</li>
                    </ul>
                </div>
            </div>
        </div>

      
        <div class="col-md-6">
            <div class="committee-card">
                <div class="card-header">
                    <h3 class="m-0">CSCA Committee</h3>
                </div>
                <div class="card-body">
                    <ul>
                        <li>Mr. Gurdev Singh</li>
                        <li>Dr. Uma Rani</li>
                        <li>Dr. Daleep Bali</li>
                        <li>Dr. Gaurav Sharma</li>
                        <li>Ms. Richa Sharma</li>
                    </ul>
                </div>
            </div>
        </div>

        
        <div class="col-md-6">
            <div class="committee-card">
                <div class="card-header">
                    <h3 class="m-0">Unnat Bharat, Shresth Bharat, Swachh Bharat Abhiyaan Committee</h3>
                </div>
                <div class="card-body">
                    <ul>
                        <li>Dr. Reena Chandel</li>
                        <li>Mr. Butta Ram</li>
                        <li>Mr. Ramesh Kumar</li>
                        <li>Ms. Jyoti Sharma</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="committee-card">
                <div class="card-header">
                    <h3 class="m-0">Cultural Committee for Youth Festival</h3>
                </div>
                <div class="card-body">
                    <ul>
                        <li>Dr. Uma Rani</li>
                        <li>Dr. Reena Chandel</li>
                        <li>Ms. Gurjeet Kaur</li>
                        <li>Mr. Ramesh Kumar</li>
                        <li>Ms. Jyoti Sharma</li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="committee-card">
                <div class="card-header">
                    <h3 class="m-0">Land & Building Affairs, College Development Committee</h3>
                </div>
                <div class="card-body">
                    <ul>
                        <li>Mr. Gurdev Singh</li>
                        <li>Dr. Daleep Bali</li>
                        <li>Dr. Gaurav Sharma</li>
                        <li>Mr. Ramesh Kumar</li>
                        <li>Shri Ashok Kumar (Storekeeper)</li>
                    </ul>
                </div>
            </div>
        </div>

        
        <div class="col-md-6">
            <div class="committee-card">
                <div class="card-header">
                    <h3 class="m-0">Water/Electricity/Furniture Committee</h3>
                </div>
                <div class="card-body">
                    <ul>
                        <li>Dr. Daleep Bali</li>
                        <li>Mr. Butta Ram</li>
                        <li>Mr. Ramesh Kumar</li>
                        <li>Shri Ashok Kumar (Supdt. Grade-II)</li>
                    </ul>
                </div>
            </div>
        </div>

      
        <div class="col-md-6">
            <div class="committee-card">
                <div class="card-header">
                    <h3 class="m-0">Mid-Term Examination Committee</h3>
                </div>
                <div class="card-body">
                    <ul>
                        <li>Dr. Reena Chandel</li>
                        <li>Mr. Gurmel Singh</li>
                        <li>Ms. Gurjeet Kaur</li>
                        <li>Shri Pawan Kumar (Assistant Librarian)</li>
                    </ul>
                </div>
            </div>
        </div>

        
        <div class="col-md-6">
            <div class="committee-card">
                <div class="card-header">
                    <h3 class="m-0">NEP Committee</h3>
                </div>
                <div class="card-body">
                    <ul>
                        <li>Dr. Gaurav Sharma (Coordinator)</li>
                        <li>Ms. Jyoti Sharma</li>
                        <li>Shri Ashok Kumar (Storekeeper)</li>
                        <li>Shri Karam Chand (Gardener)</li>
                    </ul>
                </div>
            </div>
        </div>

       
        <div class="col-md-6">
            <div class="committee-card">
                <div class="card-header">
                    <h3 class="m-0">National Service Scheme (NSS)</h3>
                </div>
                <div class="card-body">
                    <ul>
                        <li>Dr. Daleep Bali (Programme Officer)</li>
                        <li>Ms. Jyoti Sharma</li>
                        <li>Shri Ashok Kumar (Storekeeper)</li>
                    </ul>
                </div>
            </div>
        </div>

     
        <div class="col-md-6">
            <div class="committee-card">
                <div class="card-header">
                    <h3 class="m-0">Disaster Management and Anti-Drug Cell</h3>
                </div>
                <div class="card-body">
                    <ul>
                        <li>Dr. Daleep Bali</li>
                        <li>Mr. Butta Ram</li>
                        <li>Ms. Jyoti Sharma</li>
                        <li>Shri Pawan Kumar (Assistant Librarian)</li>
                    </ul>
                </div>
            </div>
        </div>

        
        <div class="col-md-6">
            <div class="committee-card">
                <div class="card-header">
                    <h3 class="m-0">Eco Club / Campus Beautification and Cleanliness Committee</h3>
                </div>
                <div class="card-body">
                    <ul>
                        <li>Ms. Richa Sharma</li>
                        <li>Ms. Gurjeet Kaur</li>
                        <li>Ms. Sunita Rani (Librarian)</li>
                        <li>Shri Karam Chand (Gardener)</li>
                    </ul>
                </div>
            </div>
        </div>

        
        <div class="col-md-6">
            <div class="committee-card">
                <div class="card-header">
                    <h3 class="m-0">SPARSH / Women Grievances Redressal Cell</h3>
                </div>
                <div class="card-body">
                    <ul>
                        <li>Dr. Uma Rani</li>
                        <li>Dr. Reena Chandel</li>
                        <li>Ms. Richa Sharma</li>
                        <li>Ms. Gurjeet Kaur</li>
                        <li>Ms. Sunita Rani (Librarian)</li>
                    </ul>
                </div>
            </div>
        </div>

       
        <div class="col-md-6">
            <div class="committee-card">
                <div class="card-header">
                    <h3 class="m-0">Students Grievances Redressal Cell</h3>
                </div>
                <div class="card-body">
                    <ul>
                        <li>Mr. Gurdev Singh (Chairperson)</li>
                        <li>Dr. Uma Rani (Member)</li>
                        <li>Dr. Gaurav Sharma (Member)</li>
                        <li>Dr. Daleep Bali (Member)</li>
                        <li>Ms. Riya (CSCA President Special Invitee)</li>
                    </ul>
                </div>
            </div>
        </div>

       
        <div class="col-md-6">
            <div class="committee-card">
                <div class="card-header">
                    <h3 class="m-0">Building Fund Committee</h3>
                </div>
                <div class="card-body">
                    <ul>
                        <li>Dr. Sunita Goyal, Principal (Chairperson)</li>
                        <li>Mr. Gurdev Singh (Senior most Teacher)</li>
                        <li>Mr. Ramesh Kumar</li>
                        <li>Smt. Paramjeet (Pradhaan Grm Panchayat Beetan)</li>
                        <li>Shri Pawan Kumar (P.T.A. President)</li>
                        <li>Shri Kuldeep Singh, J.E (Nominated Member through Xen HPPWD Una)</li>
                    </ul>
                </div>
            </div>
        </div>

        
        <div class="col-md-6">
            <div class="committee-card">
                <div class="card-header">
                    <h3 class="m-0">Career Counselling and Placement Cell</h3>
                </div>
                <div class="card-body">
                    <ul>
                        <li>Principal (Chairperson)</li>
                        <li>Mr. Gurdev Singh (Convenor)</li>
                        <li>Dr. Daleep Bali</li>
                        <li>Dr. Gaurav Sharma (Secretary)</li>
                        <li>Ms. Jyoti Sharma</li>
                        <li>Dr. Abhilasha Sharda (Retired Principal)</li>
                    </ul>
                </div>
            </div>
        </div>

        
        <div class="col-md-6">
            <div class="committee-card">
                <div class="card-header">
                    <h3 class="m-0">Refreshment Committee</h3>
                </div>
                <div class="card-body">
                    <ul>
                        <li>Dr. Reena Chandel</li>
                        <li>Mr. Gurmel Singh</li>
                        <li>Ms. Gurjeet Kaur</li>
                        <li>Mr. Ram</li>
                        <li>Mr. Vijay Kumar</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="committee-card">
                <div class="card-header">
                    <h3 class="m-0">Electoral Literacy Club/Energy Club</h3>
                </div>
                <div class="card-body">
                    <ul>
                        <li>Mr. Butta Ram (Nodal Officer)</li>
                        <li>Mr. Ramesh Kumar</li>
                        <li>Ms. Jyoti Sharma</li>
                        <li>Ms. Sunita Rani (Librarian)</li>
                    </ul>
                </div>
            </div>
        </div>

        
        <div class="col-md-6">
            <div class="committee-card">
                <div class="card-header">
                    <h3 class="m-0">Library Committee</h3>
                </div>
                <div class="card-body">
                    <ul>
                        <li>Dr. Uma Rani</li>
                        <li>Dr. Reena Chandel</li>
                        <li>Ms. Gurjeet Kaur</li>
                        <li>Mr. Ramesh Kumar</li>
                        <li>Ms. Sunita Rani (Librarian)</li>
                        <li>Shri Pawan Kumar (Assistant Librarian)</li>
                    </ul>
                </div>
            </div>
        </div>

       
        <div class="col-md-6">
            <div class="committee-card">
                <div class="card-header">
                    <h3 class="m-0">Students Wellness and Counselling Cell / First-Aid Committee</h3>
                </div>
                <div class="card-body">
                    <ul>
                        <li>Dr. Daleep Bali</li>
                        <li>Dr. Gaurav Sharma</li>
                        <li>Ms. Richa Sharma</li>
                        <li>Ms. Jyoti Sharma</li>
                    </ul>
                </div>
            </div>
        </div>

     
        <div class="col-md-6">
            <div class="committee-card">
                <div class="card-header">
                    <h3 class="m-0">Time Table Committee</h3>
                </div>
                <div class="card-body">
                    <ul>
                        <li>Dr. Daleep Bali</li>
                        <li>Dr. Gaurav Sharma</li>
                        <li>Mr. Ramesh Kumar</li>
                    </ul>
                </div>
            </div>
        </div>

      
        <div class="col-md-6">
            <div class="committee-card">
                <div class="card-header">
                    <h3 class="m-0">IT Cell / Cyber Safety Cell</h3>
                </div>
                <div class="card-body">
                    <ul>
                        <li>Mr. Gurmel Singh</li>
                        <li>Mr. Ramesh Kumar</li>
                        <li>Shri Pawan Kumar (Assistant Librarian)</li>
                    </ul>
                </div>
            </div>
        </div>

        
        <div class="col-md-6">
            <div class="committee-card">
                <div class="card-header">
                    <h3 class="m-0">National Cadet Corps: NCC Unit</h3>
                </div>
                <div class="card-body">
                    <ul>
                        <li>Mr. Ramesh Kumar (A.N.O)</li>
                        <li>Mr. Gurmel Singh</li>
                    </ul>
                </div>
            </div>
        </div>

        
        <div class="col-md-6">
            <div class="committee-card">
                <div class="card-header">
                    <h3 class="m-0">Rovers and Rangers Unit</h3>
                </div>
                <div class="card-body">
                    <ul>
                        <li>Mr. Butta Ram (In-Charge Rovers Unit)</li>
                        <li>Ms. Jyoti Sharma (In-Charge Rangers Unit)</li>
                    </ul>
                </div>
            </div>
        </div>

       
        <div class="col-md-6">
            <div class="committee-card">
                <div class="card-header">
                    <h3 class="m-0">Red Ribbon Club / Youth Red Cross / Road Safety / Gender Sensitization Cell</h3>
                </div>
                <div class="card-body">
                    <ul>
                        <li>Dr. Reena Chandel</li>
                        <li>Mr. Gurmel Singh</li>
                        <li>Mr. Butta Ram</li>
                    </ul>
                </div>
            </div>
        </div>

       
        <div class="col-md-6">
            <div class="committee-card">
                <div class="card-header">
                    <h3 class="m-0">College Magazine Committee (Editorial Board)</h3>
                </div>
                <div class="card-body">
                    <ul>
                        <li>Dr. Uma Rani (Chief Editor & Sanskrit Section)</li>
                        <li>Dr. Daleep Bali (English Section)</li>
                        <li>Dr. Reena Chandel (Hindi Section)</li>
                        <li>Ms. Richa Sharma (Science Section)</li>
                        <li>Ms. Gurjeet Kaur (Planning Section)</li>
                        <li>Mr. Ramesh Kumar (Pahari Section)</li>
                    </ul>
                </div>
            </div>
        </div>

        
        <div class="col-md-6">
            <div class="committee-card">
                <div class="card-header">
                    <h3 class="m-0">Sports Committee</h3>
                </div>
                <div class="card-body">
                    <ul>
                        <li>Dr. Gaurav Sharma (In-Charge)</li>
                        <li>Ms. Richa Sharma</li>
                        <li>Mr. Butta Ram</li>
                        <li>Ms. Sunita Rani (Librarian)</li>
                    </ul>
                </div>
            </div>
        </div>

       
        <div class="col-md-6">
            <div class="committee-card">
                <div class="card-header">
                    <h3 class="m-0">Excursion/Educational Tour Committee</h3>
                </div>
                <div class="card-body">
                    <ul>
                        <li>Dr. Uma Rani</li>
                        <li>Mr. Gurmel Singh</li>
                        <li>Ms. Richa Sharma</li>
                        <li>Mr. Butta Ram</li>
                        <li>Ms. Sunita Rani (Librarian)</li>
                    </ul>
                </div>
            </div>
        </div>

        
        <div class="col-md-6">
            <div class="committee-card">
                <div class="card-header">
                    <h3 class="m-0">General Purchase and Project Monitoring Committee RUSA</h3>
                </div>
                <div class="card-body">
                    <ul>
                        <li>Mr. Gurdev Singh</li>
                        <li>Dr. Daleep Bali</li>
                        <li>Dr. Gaurav Sharma</li>
                        <li>Ms. Richa Sharma</li>
                        <li>Ms. Sunita Rani (Librarian)</li>
                        <li>Shri Ashok Kumar (Supdt. Grade-II)</li>
                    </ul>
                </div>
            </div>
        </div>

        
        <div class="col-md-6">
            <div class="committee-card">
                <div class="card-header">
                    <h3 class="m-0">AISHE and Website Committee</h3>
                </div>
                <div class="card-body">
                    <ul>
                        <li>Mr. Gurmel Singh</li>
                        <li>Mr. Ramesh Kumar</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="committee-card">
                <div class="card-header">
                    <h3 class="m-0">Alumni/OSA Committee</h3>
                </div>
                <div class="card-body">
                    <ul>
                        <li>Mr. Gurmel Singh</li>
                        <li>Mr. Butta Ram</li>
                        <li>Shri Ashok Kumar (Supdt. Grade-II)</li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="committee-card">
                <div class="card-header">
                    <h3 class="m-0"> Equity 
                    initiative Cell/Equal Opportunity Cell.</h3>
                </div>
                <div class="card-body">
                    <ul>
                        <li>Dr. Daleep Bali</li>
                        <li>Mr. Ramesh Kumar</li>
                        <li>Ms. Sunita Rani (Librarian)</li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="committee-card">
                <div class="card-header">
                    <h3 class="m-0">Electoral Literacy Club/Energy Club</h3>
                </div>
                <div class="card-body">
                    <ul>
                        <li>Mr. Butta Ram (Nodal Officer)</li>
                        <li>Mr. Ramesh Kumar</li>
                        <li>Ms. Jyoti Sharma</li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="committee-card">
                <div class="card-header">
                    <h3 class="m-0">ABC/SWAYAM Cell</h3>
                </div>
                <div class="card-body">
                    <ul>
                        <li>Mr. Gurmel Singh</li>
                        <li>Mr. Butta Ram</li>
                        <li>Mr. Ramesh Kumar</li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="committee-card">
                <div class="card-header">
                    <h3 class="m-0">College Canteen Committee</h3>
                </div>
                <div class="card-body">
                    <ul>
                        <li>Dr. Daleep Bali</li>
                        <li>Dr. Gaurav Sharma</li>
                        <li>Ms. Gurjeet Kaur</li>
                        <li>Shri Ashok Kumar (Supdt. Grade-II)</li>
                    </ul>
                </div>
            </div>
        </div>


        <div class="col-md-6">
            <div class="committee-card">
                <div class="card-header">
                    <h3 class="m-0">Students Representatives for Grievances Cell</h3>
                </div>
                <div class="card-body">
                    <ul>
                        <li>Riya Roll No. 507 (Arts Stream)</li>
                        <li>Nazia Roll No. 456 (Commerce Stream)</li>
                        <li>Kanchan Roll No. 401 (Science Stream)</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="committee-card">
                <div class="card-header">
                    <h3 class="m-0">Equal Opportunity and SC/ST Cell</h3>
                </div>
                <div class="card-body">
                    <ul>
                        <li>Chairperson Dr Sunita Goyal (Principal)</li>
                        <li>Dr Daleep Bali (member)</li>
                        <li>Sh Butta Ram (member)</li>
                        <li>Ms Gurjeet Kaur ( Adviser)</li>
                    </ul>
                </div>
            </div>
        </div>

        


    </div>
</div>
<Footer />
        </>
    );
}

export default Committees;